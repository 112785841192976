import React from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={2}
      lesson={"Lesson 5"}
      color={"hs"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 2</H2>

        <p>Now, continue testing your rubric with some new information. Previously, your task was to think of a health-related question. You were to find at least one item that might help you answer your question. This item could be an ad, an article, or some other health-related information. You could find the item from any source, such as a website, newspaper, magazine, television, or even social media.</p>
        <p>Make sure you write your question, the title of each information item, and its source in your notebook.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

